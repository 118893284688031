<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('projects.general.user_assignments') }}
      </v-card-title>

      <v-card-text>
        <ProjectAssigneeMultiSelect v-model="assignees" :disabled="disabled" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click.native="goBack">
          {{ disabled ? $t('general.controls.close') : $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          v-if="!disabled"
          :disabled="
            $store.getters.loading[`put:api/projects/assignees/${$route.params.projectId}`]
          "
          :loading="$store.getters.loading[`put:api/projects/assignees/${$route.params.projectId}`]"
          color="primary"
          text
          @click="onSave"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProjectAssigneeMultiSelect from '@/components/form-fields/ProjectAssigneeMultiSelect';
import { mapActions, mapGetters, mapState } from 'vuex';
import clone from 'just-clone';

export default {
  name: 'ProjectTeam',

  components: { ProjectAssigneeMultiSelect },

  data() {
    return {
      assignees: [],
    };
  },

  computed: {
    ...mapState('projects', ['selectedProject']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
    ...mapGetters(['loading']),

    disabled() {
      return this.currentUserPermissionInSelectedProject !== 'admin';
    },
  },

  created() {
    this.assignees = clone(this.selectedProject.assignees);
  },

  methods: {
    ...mapActions('projects', ['updateProjectAssignees']),

    goBack() {
      this.$router.push({ name: 'project' });
    },

    onSave() {
      this.updateProjectAssignees(this.assignees).then(() => {
        this.goBack();
      });
    },
  },
};
</script>

<style scoped></style>
